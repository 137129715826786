@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;900&display=swap");
h1 {
  color: #369;
  font-weight: 500;
  font-size: 250%; }

h2, h3 {
  color: #444;
  font-weight: 500; }

body, input[type="text"], button {
  color: #888;
  font-family: 'Roboto', sans-serif; }

/* everywhere else */
@font-face {
  font-family: 'Roboto';
  src: url("./assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("./assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("./assets/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal; }
