@import 'atoms';
@import 'types';

html,section {
  background-color: $theme-body-background;
}

input:focus, textarea:focus, select:focus{
    outline: none;
}

[hidden] { display: none !important;}

.card {
  background-color: $theme-primary-background;
  height: 100%;
  text-align: center;
  transition: 0.5s;
}

.highlighted-card {
  background-color: $theme-highlight-color;
  color: $theme-alt-text;
}

.disable-select {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

.vertical-center{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%); 
  transform: translateY(-50%);
  max-height: 100vh;
}

.no-background-color {
  background-color: $seethrough-placeholder;
}

.flex-none {
  flex: none;
}

.left-text {
  text-align: left;
}

.center-text {
  text-align: center;
}

.right-text {
  text-align: right;
}

.hidden-in-desktop {
  display: none;
}

.animated {
  transition: 0.5s;
}

#yaxis-scrollbar-style::-webkit-scrollbar-track{
  background-color: $seethrough-placeholder;
  border-radius: .4rem;
  transition: 0.5s;
}

#yaxis-scrollbar-style::-webkit-scrollbar{
  width: .5rem;
  transition: 0.5s;
}

#yaxis-scrollbar-style::-webkit-scrollbar-thumb{
  background-color: $seethrough-placeholder;
  border-radius: .4rem;
  transition: 0.5s;
}

#yaxis-scrollbar-style:hover::-webkit-scrollbar-track{
  background-color: $theme-header-background;
  transition: 0.5s;
}

#yaxis-scrollbar-style:hover::-webkit-scrollbar-thumb{
  background-color: $theme-scrollbar-thumb;
  transition: 0.5s;
}

#xaxis-scrollbar-style::-webkit-scrollbar-track{
  background-color: $seethrough-placeholder;
  border-radius: .4rem;
  transition: 0.5s;
}

#xaxis-scrollbar-style::-webkit-scrollbar{
  height: .5rem;
  transition: 0.5s;
}

#xaxis-scrollbar-style::-webkit-scrollbar-thumb{
  background-color: $seethrough-placeholder;
  border-radius: .4rem;
  transition: 0.5s;
}

#xaxis-scrollbar-style:hover::-webkit-scrollbar-track{
  background-color: $theme-header-background;
  transition: 0.5s;
}

#xaxis-scrollbar-style:hover::-webkit-scrollbar-thumb{
  background-color: $theme-scrollbar-thumb;
  transition: 0.5s;
}

.v2-margin-xs {
  margin: .5rem;
}

.v2-margin-s {
  margin: 1rem;
}

.v2-margin-m {
  margin: 1.5rem;
}

.v2-margin-l {
  margin: 2rem;
}

.v2-left-padding-s {
  padding-left: 0.75rem;
}

.hyper-link {
  cursor: pointer;
}

.hover-reaction:hover {
  opacity: .5;
  transition: 0.5s;
}

.actived-btn {
  opacity: .5;
  transition: 0.5s;
}

.is-vertical-center {
  display: flex;
  align-items: center;
}

.is-total-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%,-50%);
}

.is-mobile-menu {
  height: 55vh;
  position: relative;
}

.threesixty {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;

}

.threesixty_images {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.threesixty_images img {
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
}

.threesixty .spinner {
  width: 3rem;
  display: block;
  margin: 0 auto;
  height: 2rem;
  background: #333;
  background: rgba(0, 0, 0, 0.7);
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  border-radius: .5rem;
}

.threesixty .spinner span {
  font-family: Arial, "MS Trebuchet", sans-serif;
  font-size: 1rem;
  font-weight: bolder;
  color: #FFF;
  text-align: center;
  line-height: 2rem;
  display: block;
}

.previous-image {
  visibility: hidden; 
}

.current-image {
  visibility: visible;
}

.is-full-height {
  height: 100%;
}

