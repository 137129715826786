// - Mueller Colors Theme
$theme-body-background: #3B3B3B;
$theme-header-background: #D4D4D4;
$theme-highlight-color: #EF3742;
$theme-primary-background: #FFF;
$theme-primary-text: #000;
$theme-primary-border: #61605F;
$theme-alt-text: #FFF;
$theme-search-background: #FFF;
$theme-button-border: #FFF;
$theme-search-filter: #F0F0F0;
$level-btn-background: #BE7022;
$doc-btn-background: #325691;
$seethrough-placeholder: rgba(255,255,255,0);
$theme-semi-transparent-background: rgba(0,0,0,.8);
$theme-scrollbar-thumb: rgba(0,0,0,.3);
$pin-btn-background: #5f6791;
$share-fb-btn-background: #395794;
$share-twitter-btn-background: #1D9CE8;
$share-linkedin-btn-background: #046FAC;
$share-link-btn-background: #E17639;

//Typography
// - Font Faces
  
// - Vars
$v2-type-bold: "ibm_plex_sans_bold", sans-serif;
$v2-type-text: "ibm_plex_sans_text", sans-serif;
$v2-font-size-xlarge: 3.875rem;//62px; 
$v2-font-size-large: 3.125rem;//50px;
$v2-font-size-medium: 2.25rem;//30px;
$v2-font-size-title: 1.875rem;//30px;
$v2-font-size-xnormal: 1.525rem;//22px
$v2-font-size-normal: 1.125rem;//22px
$v2-font-size-small: 1rem;//18px;
$v2-font-size-xsmall: .8rem;//18px;
$v2-font-size-xxsmall: .5rem;//18px;


